import { ArrowBack, ArrowRight, Logout } from "@mui/icons-material";
import NbcLogo from "../assets/images/nbc-logo.webp";
import { useNavigate } from "react-router-dom";

const GreenhouseServicesPage = () => {
  const navigate = useNavigate();

  const TopBar = () => {
    return (
      <div className="flex flex-row justify-between w-full px-10 py-2 gap-5">
        <button
          onClick={() => {
            navigate("/odeme-anasayfa", { replace: true });
          }}
          className=""
        >
          <ArrowBack fontSize="medium" />
        </button>
      </div>
    );
  };

  const ProgramList = () => {
    return (
      <div className="flex flex-col w-2/3 gap-3 pb-10">
        <button
          onClick={() => {
            navigate(
              "/odeme-sera-gazi-dogrulayici-ve-bas-dogrulayici-programi-egitimi",
              {
                replace: false,
              }
            );
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>
            Sera Gazı Doğrulayıcı ve Baş Doğrulayıcı Belgelendirme Programı
            Eğitimi
          </p>
          <ArrowRight fontSize="large" />
        </button>

        <button
          onClick={() => {
            navigate(
              "/odeme-sera-gazi-dogrulayici-ve-bas-dogrulayici-programi-sinavi",
              { replace: false }
            );
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>
            Sera Gazı Doğrulayıcı ve Baş Doğrulayıcı Belgelendirme Programı
            Sınavı
          </p>
          <ArrowRight fontSize="large" />
        </button>

        <button
          onClick={() => {
            navigate(
              "/odeme-sera-gazi-bas-dogrulayici-belgelendirme-programi-gozetim-izleme",
              {
                replace: false,
              }
            );
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>Sera Gazı Baş Doğrulayıcı belgelendirme programı Gözetim İzleme</p>
          <ArrowRight fontSize="large" />
        </button>

        <button
          onClick={() => {
            navigate(
              "/odeme-sera-gazi-bas-dogrulayici-belgelendirme-programi-sertifika-yenileme",
              {
                replace: false,
              }
            );
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>
            Sera Gazı Doğrulayıcı ve Baş Doğrulayıcı belgelendirme programı
            Sertifika Yenileme
          </p>
          <ArrowRight fontSize="large" />
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full items-center">
      <TopBar />
      <img src={NbcLogo} alt="nbc-logo" />
      <h1 className="text-2xl pb-10">Sera Gazı Hizmetleri</h1>
      <ProgramList />
    </div>
  );
};

export default GreenhouseServicesPage;
