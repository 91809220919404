import React from "react";
import Slider from "react-slick";
import kozmetik from "../assets/images/kozmtk.webp";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import seraGazi from "../assets/images/seraGaziGrass.webp";
import kimyasal from "../assets/images/kimyasaldue.webp";
import kereste from "../assets/images/kereste.webp";
import seraGaziHizmetler from "../assets/images/seraGaziHizmetler.webp";
import cagriHizmetler from "../assets/images/cagriHizmetler.webp";
import cagriHizmetler2 from "../assets/images/cagriHizmetler2.webp";
import turkReachHizmetler from "../assets/images/turkReach.webp"

const ServicesSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 100,
  };

  return (
    <section className="py-12 rounded-t-xl">
      <div className="container mx-auto flex flex-col gap-10">
        <Slider {...settings}>
          <div className="flex flex-col items-center justify-center">
            <div className="flex justify-center">
              <img
                src={seraGaziHizmetler}
                alt="NBC Uygunluk Değerlendirme Eğitim"
                className="w-[370px] h-[210px] object-cover rounded-lg" // Sabit genişlik ve yükseklik
              />
            </div>
            <div className="mb-8 text-center flex flex-col pt-4 gap-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Sera Gazı
              </h2>
              <div>
                <a
                  href="/sera-gazi-dogrulayici-egitimi"
                  className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-300 font-semibold"
                >
                  Eğitimlere Katılın
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="flex justify-center">
              <img
                src={kimyasal}
                alt="ISO 9001 Eğitimleri"
                className="w-[370px] h-[210px] object-cover rounded-lg" // Sabit genişlik ve yükseklik
              />
            </div>
            <div className="mb-8 text-center flex flex-col pt-4 gap-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Kimya Sektörü
              </h2>
              <div>
                <a
                  href="/kimyasal-degerlendirme-uzmani-egitimi"
                  className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors duration-300 font-semibold"
                >
                  Eğitimlere Katılın
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="flex justify-center">
              <img
                src={kereste}
                alt="ISO 17021 Eğitimleri"
                className="w-[370px] h-[210px] object-cover rounded-lg" // Sabit genişlik ve yükseklik
              />
            </div>
            <div className="mb-8 text-center flex flex-col pt-4 gap-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Ahşap Ürün Belgelendirme
              </h2>
              <div>
                <a
                  href="/kozmetik-urun-guvenlilik-degerlendirme-egitimi"
                  className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-gray-500 transition-colors duration-300 font-semibold"
                >
                  Eğitimlere Katılın
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="flex justify-center">
              <img
                src={cagriHizmetler}
                alt="ISO 17021 Eğitimleri"
                className="w-[370px] h-[210px] object-cover rounded-lg" // Sabit genişlik ve yükseklik
              />
            </div>
            <div className="mb-8 text-center flex flex-col pt-4 gap-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Çağrı Merkezi Takım Lideri Belgelendirme
              </h2>
              <div>
                <a
                  href="/kozmetik-urun-guvenlilik-degerlendirme-egitimi"
                  className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-gray-500 transition-colors duration-300 font-semibold"
                >
                  Eğitimlere Katılın
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="flex justify-center">
              <img
                src={cagriHizmetler2}
                alt="ISO 17021 Eğitimleri"
                className="w-[370px] h-[210px] object-cover rounded-lg" // Sabit genişlik ve yükseklik
              />
            </div>
            <div className="mb-8 text-center flex flex-col pt-4 gap-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Çağrı Merkezi Takım Lideri Belgelendirme
              </h2>
              <div>
                <a
                  href="/kozmetik-urun-guvenlilik-degerlendirme-egitimi"
                  className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-gray-500 transition-colors duration-300 font-semibold"
                >
                  Eğitimlere Katılın
                </a>
              </div>
            </div>
          </div>
          
          <div className="flex flex-row items-center justify-center">
            <div className="flex justify-center">
              <img
                src={turkReachHizmetler}
                alt="ISO 17021 Eğitimleri"
                className="w-[370px] h-[210px] object-cover rounded-lg" // Sabit genişlik ve yükseklik
              />
            </div>
            <div className="mb-8 text-center flex flex-col pt-4 gap-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Türk Reach
              </h2>
              <div>
                <a
                  href="/kozmetik-urun-guvenlilik-degerlendirme-egitimi"
                  className="bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-gray-500 transition-colors duration-300 font-semibold"
                >
                  Eğitimlere Katılın
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

// const NextArrow = ({ onClick }) => {
//   return (
//     <div
//       className={`
//         absolute right-8 top-1/2 transform -translate-y-1/2
//         bg-slate-100 text-slate-500 p-4 rounded-full cursor-pointer z-10
//         shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.25)]
//         transition-all
//         hover:shadow-[-1px_-1px_5px_rgba(255,_255,_255,_0.6),_1px_1px_5px_rgba(0,_0,_0,_0.3),inset_-2px_-2px_5px_rgba(255,_255,_255,_1),inset_2px_2px_4px_rgba(0,_0,_0,_0.3)]
//         hover:text-violet-500
//       `}
//       onClick={onClick}
//     >
//       <ArrowForwardIosOutlinedIcon />
//     </div>
//   );
// };

// const PrevArrow = ({ onClick }) => {
//   return (
//     <div
//       className={`
//         absolute left-8 top-1/2 transform -translate-y-1/2
//         bg-slate-100 text-slate-500 p-4 rounded-full cursor-pointer z-10
//         shadow-[-5px_-5px_10px_rgba(255,_255,_255,_0.8),_5px_5px_10px_rgba(0,_0,_0,_0.25)]
//         transition-all
//         hover:shadow-[-1px_-1px_5px_rgba(255,_255,_255,_0.6),_1px_1px_5px_rgba(0,_0,_0,_0.3),inset_-2px_-2px_5px_rgba(255,_255,_255,_1),inset_2px_2px_4px_rgba(0,_0,_0,_0.3)]
//         hover:text-violet-500
//       `}
//       onClick={onClick}
//     >
//       <ArrowBackIosOutlinedIcon />
//     </div>
//   );
// };

export default ServicesSection;
