import axios from "axios";

export const getPaymentUrl = async (tc, amount, type, name, phone, services) => {
  const res = await axios.post(
    "https://nbc-website-backend.vercel.app/payments/init",
    {
        tc: tc,
        amount: amount,
        type: type,
        name: name,
        phone: phone,
        services: services,
    },
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};
