import axios from "axios";

export const getInvoiceById = async (tc) => {
  const res = await axios.get(
    `https://nbc-website-backend.vercel.app/invoices/getByTc?tc=${tc}`,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data;
};
