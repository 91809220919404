import React from "react";
import akredte from "../assets/images/vinc.webp";
import logo from "../assets/images/logo.webp";
import { ArrowRightAlt, CheckCircle } from "@mui/icons-material";

const ContactPage = () => {
  const customStyle = {
    backgroundColor: "#030F27", // Arka plan rengi
    color: "#FFFFFF", // Metin rengi
    padding: "16px",
  };

  return (
    <section>
      <div className="relative w-full h-96">
        <img
          className="absolute h-full w-full object-cover object-center"
          src={akredte}
          alt="nature image"
        />
        <div className="absolute inset-0 h-full w-full bg-black/50"></div>
        <div className="relative pt-28 text-center">
          <h2 className="block antialiased tracking-normal font-sans font-semibold leading-[1.3] text-white mb-4 text-3xl lg:text-4xl">
            Konularında akredite ve onaylı kuruluş olarak sizlere
          </h2>
          <p className="block antialiased font-sans text-3xl font-normal leading-relaxed text-white mb-9 opacity-70">
            en iyi hizmeti vermeyi amaçlıyoruz.
          </p>
        </div>
      </div>
      <div className="-mt-8 md:-mt-16 mb-8 px-8">
        <div className="container mx-auto">
          <div className="py-12 flex gap-8 justify-center rounded-xl border border-white bg-gray-50 shadow-xl shadow-black/5 saturate-200">
            <div className="grid grid-cols-1 md:grid-cols-3">
              <div
                className="flex flex-col border-b md:border-r md:border-b-0 hover:bg-dark-blue px-10 py-10 gap-5 items-center transition-colors duration-500 group"
                style={{ color: "#04ADEF" }}
              >
                <div className="flex flex-row items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 group-hover:text-white transition-colors duration-500"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"></path>
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"></path>
                  </svg>
                  <h1 className="text-2xl group-hover:text-white transition-colors duration-500 font-semibold">
                    E-posta
                  </h1>
                </div>
                <div className="flex flex-col items-center">
                  <a
                    href="mailto:info@nbccert.com.tr"
                    className="hover:text-white transition-colors duration-500"
                  >
                    info@nbccert.com.tr
                  </a>
                  <a
                    href="mailto:aylin@nbccert.com.tr"
                    className="hover:text-white transition-colors duration-500"
                  >
                    aylin@nbccert.com.tr
                  </a>
                  <a
                    href="mailto:meltem@nbccert.com.tr"
                    className="hover:text-white transition-colors duration-500"
                  >
                    meltem@nbccert.com.tr
                  </a>
                </div>
                <a href="mailto:info@nbccert.com.tr">
                  <ArrowRightAlt
                    fontSize="large"
                    className="group-hover:text-white transition-colors duration-500"
                  />
                </a>
              </div>

              <div
                className="flex flex-col border-b md:border-r md:border-b-0 hover:bg-dark-blue px-10 py-10 gap-5 items-center transition-colors duration-500 group"
                style={{ color: "#04ADEF" }}
              >
                <div className="flex flex-row items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 group-hover:text-white transition-colors duration-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <h1 className="text-2xl group-hover:text-white transition-colors duration-500 font-semibold">
                    Telefon
                  </h1>
                </div>
                <div className="flex flex-col items-center">
                  <a>+ 90 (312)255-5558</a>
                  <a>+ 90 (552) 807 - 1171</a>
                  <a>+ 90 (552) 707 - 1171</a>
                </div>
                <button className="flex flex-row gap-2 items-center justify-center">
                  <p className="text-black group-hover:text-white transition-colors duration-500">
                    Şimdi Arayın
                  </p>
                  <ArrowRightAlt
                    fontSize="large"
                    className="transition-colors duration-500 group-hover:text-white"
                  />
                </button>
              </div>

              <div
                className="flex flex-col border-b md:border-r md:border-b-0 hover:bg-dark-blue px-10 py-10 gap-5 items-center transition-colors duration-500 group"
                style={{ color: "#04ADEF" }}
              >
                <div className="flex flex-row items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="h-6 w-6 group-hover:text-white transition-colors duration-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <h1 className="text-2xl group-hover:text-white transition-colors duration-500 font-semibold">
                    Adres
                  </h1>
                </div>
                <div className="flex flex-col items-center gap-5 text-black group-hover:text-white transition-colors duration-500">
                  <p className="text-center">
                    Kardelen Mah. 2051 Cad. Renklievler Sitesi No.1/62 15045 Ada
                    06370
                  </p>
                  <p className="text-center">Batıkent /Yenimahalle/Ankara</p>
                </div>
                <button className="flex flex-row gap-2 items-center justify-center">
                  <p className="text-black group-hover:text-white transition-colors duration-500">
                    Konum
                  </p>
                  <ArrowRightAlt
                    fontSize="large"
                    className="group-hover:text-white transition-colors duration-500"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 md:py-20 flex flex-col md:flex-row justify-center gap-10 md:gap-24 px-10 md:px-0">
        <img className="w-full md:w-1/3" src={logo} alt="" />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl font-semibold">
              NBC Uygunluk Değerlendirme Eğitim Ltd. Şti.
            </h1>
            <div
              style={{ backgroundColor: "#22d3ee" }}
              className="w-full md:w-1/3 h-1"
            />
          </div>
          <div className="flex flex-col justify-between flex-1">
            <div className="flex flex-row gap-2">
              <CheckCircle style={{ color: "#22d3ee" }} />
              <p className="">Uzman Kadro</p>
            </div>
            <div className="flex flex-row gap-2">
              <CheckCircle style={{ color: "#22d3ee" }} />
              <p className="">Deneyimli Eğitmenler</p>
            </div>
            <div className="flex flex-row gap-2">
              <CheckCircle style={{ color: "#22d3ee" }} />
              <p className="">Akredite Kuruluş</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-96 px-10 mb-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.618189620236!2d32.7029023!3d39.9722884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d337f1a1a3dae9%3A0xb6e8df1f022415df!2zTkJDIFVZR1V1TlVLSERFIEdJTk5FUkxFTkTEsFJNRSBFxJ7EsFTEsE0gTFRELsWeVMSw!5e0!3m2!1str!2str!4v1727512652434!5m2!1str!2str"
          className="w-full h-full border-0"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactPage;
