export const getFixedDate = (timestamp) => {
  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
  const date = new Date(milliseconds);
  var day = date.getDate();
  var month = date.getMonth();
  var year = date.getFullYear();

  if (day.toString().length === 1) {
    day = `0${day}`;
  }

  if (month.toString().length === 1) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year}`;
};
