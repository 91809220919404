import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import brochureImage from "../assets/images/brochure.webp";

const FullScreenDialog = () => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const isDialogShown = sessionStorage.getItem('dialogShown');
    if (!isDialogShown) {
      setOpen(true);
      sessionStorage.setItem('dialogShown', 'true');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          padding: 0,
        }}
      >
        <img
          src={brochureImage}
          alt="Welcome"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default FullScreenDialog;
