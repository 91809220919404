import { Logout, Settings } from "@mui/icons-material";
import NbcLogo from "../assets/images/nbc-logo.webp";
import documentationServiceImage from "../assets/images/documentation-services-card.webp";
import greenhouseServiceImage from "../assets/images/greenhouse-service-card.webp";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const TopBar = () => {
    return (
      <div className="flex flex-row justify-end w-full px-10 py-2 gap-5">
        <button onClick={() => {}} className="">
          <Settings fontSize="medium" />
        </button>
        <button onClick={() => {}} className="text-red-500">
          <Logout fontSize="medium" />
        </button>
      </div>
    );
  };

  const DocumentationServicesCard = () => {
    return (
      <button
        onClick={() => {
          navigate("/odeme-belgelendirme-hizmetleri", { replace: false });
        }}
        className="relative flex flex-col w-full md:w-2/5 items-center justify-center group"
      >
        <img
          src={documentationServiceImage}
          className="w-full h-full rounded-lg transition transform duration-500 hover:blur-sm"
        />
        <p className="absolute text-white text-3xl opacity-0 pointer-events-none transition-opacity duration-500 ease-in-out group-hover:opacity-100">
          Belgelendirme Hizmetleri
        </p>
      </button>
    );
  };

  const GreenHouseGasServiceCard = () => {
    return (
      <button
        onClick={() => {
          navigate("/odeme-sera-gazi-hizmetleri", { replace: false });
        }}
        className="relative flex flex-col w-full md:w-2/5 items-center justify-center group"
      >
        <img
          src={greenhouseServiceImage}
          className="w-full h-full rounded-lg transition transform duration-500 hover:blur-sm"
        />
        <p className="absolute text-white text-3xl opacity-0 pointer-events-none transition-opacity duration-500 ease-in-out group-hover:opacity-100">
          Sera Gazı Hizmetleri
        </p>
      </button>
    );
  };

  return (
    <div className="flex flex-col w-full h-full items-center px-10">
      <img src={NbcLogo} />
      <div className="flex flex-col md:flex-row w-full gap-10 justify-center pt-10">
        <DocumentationServicesCard />
        <GreenHouseGasServiceCard />
      </div>
      <button onClick={() => {
        navigate("/odeme-sorgula", { replace: false });
      }} className="my-10 bg-blue-400 px-4 py-2 rounded-md text-white transition-transform duration-300 hover:scale-105">Ödeme Sorgula</button>
    </div>
  );
};

export default HomePage;
