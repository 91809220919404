import { ArrowBack, CheckBox, Logout } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CallCenterProgramDetailsPage = () => {
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const [index, setIndex] = useState(0);

  const productsLevel4 = [
    {
      id: "BC41",
      name: "15UY0203-4/A1 İş Sağlığı ve Güvenliği, Çevre Koruma, Kalite, İş Organizasyonu ve Mesleki Gelişim",
      price: 350,
    },
    {
      id: "BC42",
      name: "15UY0203-4/A2 Çağrı Yönetme Faaliyetleri/ T1",
      price: 350,
    },
    {
      id: "BC43",
      name: "15UY0203-4/P1 Bilgi İçeriği Çağrı Uygulaması",
      price: 810,
    },
    {
      id: "BC44",
      name: "15UY0203-4/P2 Şikâyet İçeriği Çağrı Uygulaması",
      price: 810,
    },
    {
      id: "BC45",
      name: "15UY0203-4/P3 Satış İçeriği Çağrı Uygulaması",
      price: 810,
    },
  ];

  const productsLevel5 = [
    {
      id: "BC51",
      name: "15UY0204-5/A1 İş Sağlığı ve Güvenliği, Çevre Koruma, Kalite, İnsan Kaynakları Süreçleri ve Mesleki Gelişim",
      price: 350,
    },
    {
      id: "BC52",
      name: "15UY0204-5/A2 Çağrı Merkezi Yönetme Faaliyetleri /T1",
      price: 350,
    },
    {
      id: "BC53",
      name: "15UY0204-5/A2 Çağrı Merkezi Yönetme Faaliyetleri",
      price: 1200,
    },
    {
      id: "BC54",
      name: "15UY0204-5/A2 Çağrı Merkezi Yönetme Faaliyetleri",
      price: 810,
    },
  ];

  const products = index === 0 ? productsLevel4 : productsLevel5;

  const [selectedProducts, setSelectedProducts] = useState([]);

  const TopBar = () => {
    return (
      <div className="flex flex-row justify-between w-full px-10 py-2 gap-5">
        <button
          onClick={() => {
            navigate("/odeme-belgelendirme-hizmetleri");
          }}
        >
          <ArrowBack fontSize="medium" />
        </button>
      </div>
    );
  };

  const Content = () => {
    return (
      <div className="flex flex-col w-full md:w-2/3 h-full shadow-lg border border-gray-200 rounded-sm gap-8 p-4">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-3">
            <button
              onClick={() => {
                if (index === 0) {
                  return;
                }
                setIndex(0);
                setSelectedProducts([]);
                setTotalPrice(0);
              }}
              className={`rounded-lg py-1 px-2 text-sm ${
                index === 0
                  ? "bg-blue-400 text-white"
                  : "bg-white text-black border border-black"
              }`}
            >
              Çağrı Merkezi Müşteri Temsilcisi – Seviye 4
            </button>
            <button
              onClick={() => {
                if (index === 1) {
                  return;
                }
                setIndex(1);
                setSelectedProducts([]);
                setTotalPrice(0);
              }}
              className={`rounded-lg py-1 px-2 text-sm ${
                index === 1
                  ? "bg-blue-400 text-white"
                  : "bg-white text-black border border-black"
              }`}
            >
              Çağrı Merkezi Takım Lideri – Seviye 5
            </button>
          </div>
          <h1 className="font-bold">Almak istediğiniz servisleri seçin:</h1>
          <div className="flex flex-col pl-4 gap-2">
            <h1 className="font-semibold">
              {index === 0
                ? "Çağrı Merkezi Müşteri Temsilcisi – Seviye 4"
                : "Çağrı Merkezi Takım Lideri – Seviye 5"}
            </h1>
            {products.map((product, index) => (
              <div key={index} className="flex flex-row items-center gap-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-500"
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => {
                    if (selectedProducts.includes(product.id)) {
                      setSelectedProducts(
                        selectedProducts.filter((id) => id !== product.id)
                      );
                      setTotalPrice(totalPrice - product.price);
                    } else {
                      setSelectedProducts([...selectedProducts, product.id]);
                      setTotalPrice(totalPrice + product.price);
                    }
                  }}
                />
                <label className="ml-2">{product.name}</label>
                <label className="ml-2">{product.price} TL</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const SelectedProductsCard = () => {
    const isButtonDisabled = totalPrice <= 0;
    var servicesText = "";
    selectedProducts.forEach((product) => {
      servicesText += `${product}-`;
    });
    servicesText = servicesText.substring(0, servicesText.length - 1);

    return (
      <div className="flex flex-col w-full md:w-1/3 h-full gap-8">
        <div className="flex flex-col shadow-lg border border-gray-200 rounded-sm px-5 py-4 gap-5">
          <h1>Seçtiğiniz hizmetler:</h1>
          <div className="flex flex-col gap-1">
            {selectedProducts.map((id, index) => {
              const product = [...productsLevel4, ...productsLevel5].filter(
                (p) => {
                  return p.id === id;
                }
              );

              return (
                <div key={index} className="flex flex-row items-center gap-2">
                  <CheckBox color="success" />
                  <p className="text-sm">{product[0].name}</p>
                </div>
              );
            })}
          </div>
          <p className="text-end">Toplam: {totalPrice} TL</p>
          <button
            onClick={async () => {
              navigate(
                `/odeme?amount=${totalPrice}&type=Çağrı merkezi belgelendirme programı&service=${servicesText}`
              );
            }}
            className={`rounded-xl py-2 text-white ${
              isButtonDisabled ? "bg-gray-400" : "bg-blue-500"
            }`}
            disabled={isButtonDisabled}
          >
            İlerle
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <TopBar />
      <h1 className="text-center text-xl md:text-3xl px-10">
        Çağrı merkezi belgelendirme programı
      </h1>
      <div className="flex flex-col-reverse md:flex-row h-full py-10 px-10 gap-3">
        <Content />
        <SelectedProductsCard />
      </div>
    </div>
  );
};

export default CallCenterProgramDetailsPage;
