import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { getPaymentUrl } from "../services/PaymentService";

const UserInformationsForm = ({ amount, type, services }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [tc, setTc] = useState("");
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex flex-col gap-5 border border-gray-300 shadow-2xl p-4 md:p-10 rounded-lg w-full md:w-1/2">
      <form className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row w-full gap-4">
          <label className="flex flex-col gap-1 w-full md:w-1/2">
            <p>
              <span className="text-red-400">* </span>İsim:
            </p>
            <input
              className="border border-black p-2 rounded-lg"
              type="text"
              name="name"
              onChange={(value) => setName(value.target.value)}
            />
          </label>
          <label className="flex flex-col gap-1 w-full md:w-1/2">
            <p>
              <span className="text-red-400">* </span>Soyisim:
            </p>
            <input
              className="border border-black p-2 rounded-lg"
              type="text"
              name="surname"
              onChange={(value) => setSurname(value.target.value)}
            />
          </label>
        </div>
        <label className="flex flex-col gap-1 w-full">
          <p>
            <span className="text-red-400">* </span>TC Kimlik Numarası:
          </p>
          <input
            className="border border-black p-2 rounded-lg"
            type="text"
            name="tc"
            onChange={(value) => setTc(value.target.value)}
          />
        </label>
        <label className="flex flex-col gap-1">
          <p>
            <span className="text-red-400">* </span>Telefon Numarası:
          </p>
          <input
            className="border border-black p-2 rounded-lg"
            type="text"
            name="phone"
            onChange={(value) => setPhone(value.target.value)}
          />
        </label>
        <button
          onClick={async () => {
            setIsLoading(true);
            if (name === "" || surname === "" || tc === "" || phone === "") {
              alert("Tüm alanları doldurmanız gerek!");
              setIsLoading(false);
              return;
            }
            if(tc.length !== 11) {
              alert("TC Kimlik Numarası 11 haneli olmalıdır!");
              setIsLoading(false);
              return;
            }
            if(phone.length !== 11) {
              alert("Telefon Numarası başında 0 olacak şekilde 11 haneli olmalıdır!");
              setIsLoading(false);
              return;
            }
            
            const paymentUrl = await getPaymentUrl(
              tc,
              amount,
              type,
              `${name} ${surname}`,
              phone,
              services
            );
            window.location.href = paymentUrl;
          }}
          className="bg-blue-500 rounded-lg p-3"
          type="button"
        >
          {isLoading ? (
            <PulseLoader size={6} speedMultiplier={0.8} />
          ) : (
            <p className="text-white">Onayla</p>
          )}
        </button>
        <p>
          <span className="text-red-400">* </span>Ödeme yapmadan önce bu
          bilgileri girmeniz gereklidir.
        </p>
      </form>
    </div>
  );
};

export default UserInformationsForm;
