import { ArrowBack, CheckBox, Logout } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SecurityInformationFormProgramDetailsPage = () => {
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);

  const products = [
    {
      id: "BG1",
      name: "Güvenlik Bilgi Formu Hazırlayıcısı Belgelendirme Programı Sınav Ücreti",
      price: 800,
    },
    {
      id: "Deneme",
      name: "Deneme Ödemesi",
      price: 5,
    }
  ];

  const [selectedProducts, setSelectedProducts] = useState([]);

  const TopBar = () => {
    return (
      <div className="flex flex-row justify-between w-full px-10 py-2 gap-5">
        <button
          onClick={() => {
            navigate("/odeme-belgelendirme-hizmetleri");
          }}
        >
          <ArrowBack fontSize="medium" />
        </button>
      </div>
    );
  };

  const Content = () => {
    return (
      <div className="flex flex-col w-full md:w-2/3 h-full gap-8 shadow-lg border border-gray-200 rounded-sm p-4">
        <div className="flex flex-col gap-4">
          <p className="font-bold text-xl">
            Güvenlik Bilgi Formu Hazırlayıcısı Belgelendirme Programı Sınav
            Ücreti:
          </p>
          <p className="text-lg">1 kişi için 800 TL’dir.</p>
          <div className="flex flex-col text-lg">
            <p>*Belge ücreti dahildir</p>
            <p>*Belirtilen ücretlere KDV dahil değildir</p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <h1 className="font-bold">Almak istediğiniz servisi seçin:</h1>
          <div className="flex flex-col pl-4 gap-2">
            {products.map((product, index) => (
              <div key={index} className="flex flex-row items-center gap-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-blue-500"
                  checked={selectedProducts.includes(product.id)}
                  onChange={() => {
                    if (selectedProducts.includes(product.id)) {
                      setSelectedProducts(
                        selectedProducts.filter((id) => id !== product.id)
                      );
                      setTotalPrice(totalPrice - product.price);
                    } else {
                      setSelectedProducts([...selectedProducts, product.id]);
                      setTotalPrice(totalPrice + product.price);
                    }
                  }}
                />
                <label className="ml-2">{product.name}</label>
                <label className="ml-2">{product.price} TL</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const SelectedProductsCard = () => {
    const isButtonDisabled = totalPrice <= 0;
    var servicesText = "";
    selectedProducts.forEach((product) => {
      servicesText += `${product}-`;
    })
    servicesText = servicesText.substring(0, servicesText.length-1);

    return (
      <div className="flex flex-col w-full md:w-1/3 h-full gap-8">
        <div className="flex flex-col shadow-lg border border-gray-200 rounded-sm px-5 py-4 gap-5">
          <h1>Seçtiğiniz hizmetler:</h1>
          <div className="flex flex-col gap-1">
            {selectedProducts.map((id, index) => {
              const product = products.filter((p) => {
                return p.id === id;
              });

              return (
                <div key={index} className="flex flex-row items-center gap-2">
                  <CheckBox color="success" />
                  <p className="text-sm">{product[0].name}</p>
                </div>
              );
            })}
          </div>
          <p className="text-end">Toplam: {totalPrice} TL</p>
          <button
            onClick={async () => {
              navigate(
                `/odeme?amount=${totalPrice}&type=Güvenlik Bilgi Formu Hazırlayıcısı Belgelendirme Programı&service=${servicesText}`
              );
            }}
            className={`rounded-xl py-2 text-white ${
              isButtonDisabled ? "bg-gray-400" : "bg-blue-500"
            }`}
            disabled={isButtonDisabled}
          >
            İlerle
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <TopBar />
      <h1 className="text-center text-xl md:text-3xl px-10">
        Güvenlik Bilgi Formu Hazırlayıcısı Belgelendirme Programı
      </h1>
      <div className="flex flex-col-reverse md:flex-row h-full py-10 px-10 gap-3">
        <Content />
        <SelectedProductsCard />
      </div>
    </div>
  );
};

export default SecurityInformationFormProgramDetailsPage;
