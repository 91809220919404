import { ArrowBack, ArrowRight, Logout, Settings } from "@mui/icons-material";
import NbcLogo from "../assets/images/nbc-logo.webp";
import { useNavigate } from "react-router-dom";

const DocumentationServicesPage = () => {
  const navigate = useNavigate();

  const TopBar = () => {
    return (
      <div className="flex flex-row justify-between w-full px-10 py-2 gap-5">
        <button
          onClick={() => {
            navigate("/odeme-anasayfa", { replace: true });
          }}
          className=""
        >
          <ArrowBack fontSize="medium" />
        </button>
      </div>
    );
  };

  const ProgramList = () => {
    return (
      <div className="flex flex-col w-2/3 gap-3 pb-10">
        <button
          onClick={() => {
            navigate("/odeme-güvenlik-bilgi-formu-hazırlayıcısı-belgelendirme", {
              replace: false,
            });
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>Güvenlik Bilgi Formu Hazırlayıcısı Belgelendirme Programı</p>
          <ArrowRight fontSize="large" />
        </button>

        <button
          onClick={() => {
            navigate("/odeme-cagri-merkezi-belgelendirme-programi");
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>Çağrı merkezi belgelendirme programı</p>
          <ArrowRight fontSize="large" />
        </button>

        <button
          onClick={() => {
            navigate("/odeme-kimyasal-degerlendirme-uzmani-belgelendirme-programi");
          }}
          className="flex flex-row w-full justify-between items-center bg-blue-400 rounded-xl py-4 px-5 text-white hover:scale-105 transform transition-transform duration-300"
        >
          <p>Kimyasal Değerlendirme Uzmanı Belgelendirme Programı</p>
          <ArrowRight fontSize="large" />
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full items-center">
      <TopBar />
      <img src={NbcLogo} />
      <h1 className="text-2xl pb-10">Belgelendirme Hizmetleri</h1>
      <ProgramList />
    </div>
  );
};

export default DocumentationServicesPage;
