import UserInformationsForm from "../components/UserInformationsForm";

const UserInformationsPage = () => {
    const params = new URLSearchParams(window.location.search);
    const amountString = params.get("amount");
    const amount = parseInt(amountString) * 100
    const type = params.get("type");
    const services = params.get("service");

    return(
        <div className="flex flex-col min-h-screen w-full items-center py-24 gap-10 px-2 md:px-0">
            <h1 className="text-xl">Bilgilerinizi Girin</h1>
            <UserInformationsForm amount={amount} type={type} services={services} />
        </div>
    );
}

export default UserInformationsPage;