import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Documents from "../pages/Documents";
import Contact from "../pages/Contact";
import SeraGaziEgitim from "../pages/SeraGaziEgitim";
import KimyasalDegerlendirmeEgitim from "../pages/KimyasalDegerlendirmeEgitimi";
import KozmetikUrunGuvenlilik from "../pages/KozmatikUrunGuvenlilik";
import SeraGazi from "../pages/SeraGazi";
import KimyaSektoru from "../pages/KimyaSektoru";
import TurkReach from "../pages/TurkReach";
import CagriMerkeziLider from "../pages/CagriMerkeziLider";
import EducationTable from "../pages/EducationTable";
import AhsapUrunBelgelendirme from "../pages/AhsapUrunBelgelendirme";
import BelgeDogrulama from "../pages/BelgeDogrulama";
import CagriMerkezi from "../pages/CagriMerkezi";
import HomePage from "../pages/HomePage";
import DocumentationServicesPage from "../pages/DocumentationServicesPage";
import GreenhouseServicesPage from "../pages/GreenhouseServicesPage";
import SearchInvoicePage from "../pages/SearchInvoicePage";
import SecurityInformationFormProgramDetailsPage from "../pages/SecurityInformationFormProgramDetailsPage";
import CallCenterProgramDetailsPage from "../pages/CallCenterProgramDetailPage"
import ChemicalAssesmentSpecialistProgramDetailsPage from "../pages/ChemicalAssesmentSpecialistProgramDetailPage";
import GreenhouseGasVerifierEducatuionDetailPage from "../pages/GreenhouseGasVerifierEducationDetailPage";
import GreenhouseGasVerifierExamDetailPage from "../pages/GreenhouseGasVerifierExamDetailPage";
// import ChemicalAssesmentSpecialistProgramDetailsPage from "../pages/ChemicalAssesmentSpecialistProgramDetailsPage";
import GreenhouseGasVerifierSurveillenceMonitoringDetailPage from "../pages/GreenhouseGasVerifierSurveillenceMonitoringDetailPage";
import GreenhouseGasVerifierCertificateRenewalDetailPage from "../pages/GreenhouseGasVerifierCertificateRenewalDetailPage";
import UserInformationsPage from "../pages/UserInformationsPage";

const RoutePath = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/hakkimizda" element={<AboutUs />} />
      <Route path="/dokumanlar" element={<Documents />} />
      <Route path="/iletisim" element={<Contact />} />
      <Route path="/sera-gazi-dogrulayici-egitimi" element={<SeraGaziEgitim />} />
      <Route
        path="/kimyasal-degerlendirme-uzmani-egitimi"
        element={<KimyasalDegerlendirmeEgitim />}
      />
      <Route
        path="/kozmetik-urun-guvenlilik-degerlendirme-egitimi"
        element={<KozmetikUrunGuvenlilik />}
      />
      <Route path="/sera-gazi" element={<SeraGazi />} />
      <Route path="/kimya-sektoru" element={<KimyaSektoru />} />
      <Route path="/turkreach" element={<TurkReach />} />
      <Route path="/cagri-merkezi-musteri-temsilcisi-sinav-ve-belgelendirme-takim-lideri-sinav-ve-belgelendirme" element={<CagriMerkeziLider />} />
      <Route path="/egitim-takvimi" element={<EducationTable />} />
      <Route path="/ahsap-urun-belgelendirme" element={<AhsapUrunBelgelendirme />} />
      <Route path="/belge-sorgulama" element={<BelgeDogrulama />} />
      <Route path="/cagri-merkezi-musteri-temsilcisi-sinav-ve-belgelendirme" element={<CagriMerkezi />} />

      {/* Payment Routes */}
      <Route path="/odeme-anasayfa" element={<HomePage />} />
      <Route
        path="/odeme-belgelendirme-hizmetleri"
        element={<DocumentationServicesPage />}
      />
      <Route
        path="/odeme-sera-gazi-hizmetleri"
        element={<GreenhouseServicesPage />}
      />
      <Route path="/odeme-sorgula" element={<SearchInvoicePage />} />
      <Route
        path="/odeme-güvenlik-bilgi-formu-hazırlayıcısı-belgelendirme"
        element={<SecurityInformationFormProgramDetailsPage />}
      />
      <Route
        path="/odeme-cagri-merkezi-belgelendirme-programi"
        element={<CallCenterProgramDetailsPage />}
      />
      <Route
        path="/odeme-kimyasal-degerlendirme-uzmani-belgelendirme-programi"
        element={<ChemicalAssesmentSpecialistProgramDetailsPage />}
      />
      <Route
        path="/odeme-sera-gazi-dogrulayici-ve-bas-dogrulayici-programi-egitimi"
        element={<GreenhouseGasVerifierEducatuionDetailPage />}
      />
      <Route
        path="/odeme-sera-gazi-dogrulayici-ve-bas-dogrulayici-programi-sinavi"
        element={<GreenhouseGasVerifierExamDetailPage />}
      />
      <Route
        path="/odeme-kimyasal-degerlendirme-uzmani-programi"
        element={<ChemicalAssesmentSpecialistProgramDetailsPage />}
      />
      <Route
        path="/odeme-sera-gazi-bas-dogrulayici-belgelendirme-programi-gozetim-izleme"
        element={<GreenhouseGasVerifierSurveillenceMonitoringDetailPage />}
      />
      <Route
        path="/odeme-sera-gazi-bas-dogrulayici-belgelendirme-programi-sertifika-yenileme"
        element={<GreenhouseGasVerifierCertificateRenewalDetailPage />}
      />
      <Route path="/odeme" element={<UserInformationsPage />} />
    </Routes>
  );
};

export default RoutePath;
