export const services = {
    "BG1": "Güvenlik Bilgi Formu Hazırlayıcısı Belgelendirme Programı Sınav Ücreti",
    "BC41": "15UY0203-4/A1 İş Sağlığı ve Güvenliği, Çevre Koruma, Kalite, İş Organizasyonu ve Mesleki Gelişim",
    "BC42": "15UY0203-4/A2 Çağrı Yönetme Faaliyetleri/ T1",
    "BC43": "15UY0203-4/P1 Bilgi İçeriği Çağrı Uygulaması",
    "BC44": "15UY0203-4/P2 Şikâyet İçeriği Çağrı Uygulaması",
    "BC45": "15UY0203-4/P3 Satış İçeriği Çağrı Uygulaması",
    "BC51": "15UY0204-5/A1 İş Sağlığı ve Güvenliği, Çevre Koruma, Kalite, İnsan Kaynakları Süreçleri ve Mesleki Gelişim",
    "BC52": "15UY0204-5/A2 Çağrı Merkezi Yönetme Faaliyetleri /T1",
    "BC53": "15UY0204-5/A2 Çağrı Merkezi Yönetme Faaliyetleri",
    "BC54": "15UY0204-5/A2 Çağrı Merkezi Yönetme Faaliyetleri",
    "BK1": "Eğitim Ücreti",
    "BK2": "Sınav Ücreti",
    "S11": "Eğitim Ücreti",
    "S12": "İngilizce Sertifika",
    "S21": "Sera Gazı Doğrulayıcı Sınav Ücreti",
    "S22": "Sera Gazı Baş Doğrulayıcı Sınav Ücreti",
    "S31": "Sera Gazı Baş Doğrulayıcı",
    "S41": "Sera Gazı Doğrulayıcı Sertifika Yenileme",
    "S42": "Sera Gazı Baş Doğrulayıcı Sertifika Yenileme",
    "Deneme": "Deneme Ödemesi",
}