import { ArrowBack, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getInvoiceById } from "../services/InvoiceService";
import { useState } from "react";
import { services } from "../constants";
import { getFixedDate } from "../services/UtilsService";

const SearchInvoicePage = () => {
  const navigate = useNavigate();
  const [tc, setTc] = useState("");
  const [result, setResult] = useState([]);

  const TopBar = () => {
    return (
      <div className="flex flex-row justify-between w-full px-10 py-2 gap-5">
        <button
          onClick={() => {
            navigate("/odeme-anasayfa");
          }}
        >
          <ArrowBack fontSize="medium" />
        </button>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <TopBar />
      <h1 className="text-center text-3xl">
        TC kimlik numarası ile ödeme sorgulama
      </h1>
      <div className="flex flex-col h-full py-10 px-10 gap-3 items-center">
        <div className="flex flex-row w-2/3 items-center gap-2">
          <input
            type="search"
            className="border border-gray-300 rounded-sm flex-1 py-2 px-4 outline-none focus:border-blue-400"
            placeholder="TC Kimlik Numarası"
            value={tc}
            onChange={(value) => setTc(value.target.value)}
          />
          <button
            onClick={async () => {
              const result = await getInvoiceById(tc);

              const filteredInvoices = result.filter((data) => data.status === "completed");
              setResult(filteredInvoices);
            }}
          >
            <Search fontSize="medium" />
          </button>
        </div>
        <div className="flex flex-col w-full items-center gap-2 pt-4">
          <div className="flex flex-row w-full items-center border-b border-black gap-2 px-2">
            <p className="w-2/12 text-start">Tc Kimlik Numarası</p>
            <p className="w-4/12 text-start">Program</p>
            <p className="w-5/12 text-start">Servisler</p>
            <p className="w-1/12 text-start">Tarih</p>
          </div>
          <div className="flex flex-col gap-4 w-full">
            {result.map((res, index) => (
              <div
                key={index}
                className="flex flex-row w-full gap-2 border-b pb-2 px-2 items-center"
              >
                <p className="w-2/12 text-start text-gray-600">{res.tc}</p>
                <p className="w-4/12 text-start text-gray-600">{res.type}</p>
                <div className="flex flex-col w-5/12 items-start justify-center gap-2">
                  {res.services.map((service, index) => (
                    <p key={`1-${index}`} className="text-sm text-gray-600">
                      - {services[service]}
                    </p>
                  ))}
                </div>
                <p className="w-1/12 text-start text-gray-600">{getFixedDate(res.created_at)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInvoicePage;
