import "./App.css";
import Navbar from "./components/Navbar";
import MainNavbar from "./components/MainNavbar";
import RoutePath from "./routers/RoutePath";
import Footer from "./components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import BrochureDialog from "./components/BrochureDialog";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  console.log(location.pathname.startsWith("odeme"));

  useEffect(() => {
    if (
      location.pathname === "/kozmetik-urun-guvenlilik-degerlendirmesi-copy/"
    ) {
      navigate("/kozmetik-urun-guvenlilik-degerlendirme-egitimi", {
        replace: true,
      });
    } else if (
      location.pathname.startsWith("/kozmetik-urun-guvenlilik-degerlendirmesi")
    ) {
      navigate("/kozmetik-urun-guvenlilik-degerlendirme-egitimi", {
        replace: true,
      });
    } else if (
      location.pathname.startsWith("/belgesorgulama")
    ) {
      navigate("/belge-sorgulama", {
        replace: true,
      });
    }
  }, []);

  return (
    <div className="flex flex-col">
      {location.pathname === "/" ? <BrochureDialog /> : null}
      {location.pathname.startsWith("/odeme") ? null : <Navbar />}
      {location.pathname.startsWith("/odeme") ? null : <MainNavbar />}
      <RoutePath />
      {location.pathname.startsWith("/odeme") ? null : <Footer />}
    </div>
  );
}

export default App;
