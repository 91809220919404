import React from "react";
import images from "../assets/images/imagesHero.webp"; // Görseli import ediyoruz
import Card from "./Cards"; // Kart bileşenini import ediyoruz
import cardResim from "../assets/images/seraGaziGrass.webp";
import cardResim1 from "../assets/images/hakkimizdaKimya.webp";
import cardResim2 from "../assets/images/isyonetimi.webp";
import cardResim3 from "../assets/images/chemicalMolecule.webp";
import ahsap from "../assets/images/kereste.webp";
import { TypeAnimation } from "react-type-animation";
import video1 from "../assets/images/nbc2.mp4";

const Hero = () => {
  // Kart verileri
  const cards = [
    {
      title: "Sera Gazı",
      subtitle: "Emisyonları Doğrulama İlkeleri ve Hesaplamaları",
      description:
        "Sera Gazı Emisyonları, Sera Gazı Emisyonları İzleme Ve Raporlama İlkeleri, Sera Gazı",
      image: cardResim,
    },
    {
      title: "Kimya Sektörü",
      subtitle: "Kimya Sektörü",
      description:
        "Kimyasalların Kaydı Değerlendirmesi İzni ve Kısıtlanması (KKDİK), Kimyasal Değerlendirme Uzmanı (KDU)",
      image: cardResim1,
    },
    {
      title: "İş ve Yönetim",
      subtitle: "",
      description:
        "Çağrı merkezi çalışanları, anketörler, meslek ve KOBİ danışmanları, kariyer ve yetenek yönetim uzmanları",
      image: cardResim2,
    },
    {
      title: "TÜRK REACH",
      subtitle: "",
      description:
        "Registration, Evaluation, Authorisation and Restriction of Chemicals, Kimyasalların Kaydı, Değerlendirilmesi, İzni ve Kısıtlanması ",
      image: cardResim3,
    },
    {
      title: "Ahşap Ürün Belgelendirme",
      subtitle: "",
      description: "Ahşap Ürün CE Belgelendirme çalışmaları",
      image: ahsap,
    },
  ];

  return (
    <div className="relative min-h-screen z-10">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-2/3 object-cover -z-10"
      >
        <source src={video1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content of the div */}
      <div className="relative z-10">
        {/* Overlay */}
        {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}

        {/* İçerik */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white pb-4 pt-48">
          {/* Başlık */}
          {/* <h1 className="text-4xl lg:text-6xl font-bold mb-6">
                  Yetkin, Güvenilir, Tarafsız Sertifikasyon
              </h1> */}
          <h1 className="text-white text-4xl md:text-6xl font-bold italic mb-6">
            <span className="primary-color">NBC </span>
            <TypeAnimation
              sequence={[
                " Uygunluk",
                3000,
                " Değerlendirme",
                3000,
                " Eğitim",
                3000,
              ]}
              wrapper="span"
              speed={2}
              repeat={Infinity}
            />
          </h1>

          {/* Açıklama */}
          <p className="text-lg lg:text-2xl mb-8 max-w-3xl">
            NBC Sertifikasyon olarak sektördeki uzmanlığımız ve kaliteli hizmet
            anlayışımızla...
          </p>

          {/* Butonlar */}
          <div className="flex space-x-4 mb-10 px-6">
            <a
              href="/iletisim"
              className="px-8 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg text-white font-semibold transition duration-300"
            >
              Hemen Başvur
            </a>
            <a
              href="/iletisim"
              className="px-8 py-3 bg-transparent border-2 border-white hover:bg-white hover:text-black rounded-lg font-semibold transition duration-300"
            >
              Daha Fazla Bilgi
            </a>
          </div>

          {/* Kartlar */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5  text-black gap-6 px-6 mt-10">
            {cards.map((card, index) => (
              <Card
                key={index}
                title={card.title}
                subtitle={card.subtitle}
                description={card.description}
                image={card.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>

    
  );
};

export default Hero;
