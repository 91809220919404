import React from "react";
import Hero from "../components/Hero";
import BelgeSorgula from "../components/BelgeSorgula";
import Yeterliliklerimiz from "../components/Yeterliliklerimiz";
import TrainingSection from "../components/TrainingSection";
import CardContainer from "../components/CardContainer";
import ServicesSection from "../components/ServicesSection";
import { useState } from "react";
import { useEffect } from "react";

const Home = () => {
  const [sliderIndex, setSliderIndex] = useState(0);

  // useEffect(() => {

  // }, [sliderIndex])

  return (
    <div>
      <Hero />
      <br />
      <br />
      <BelgeSorgula />
      <br />
      <br />
      <Yeterliliklerimiz />
      {/* <CardContainer/> */}
      <div className="flex flex-col py-4">
        <div className="flex flex-row items-center justify-center gap-4">
          <button
            onClick={() => {
              if (sliderIndex !== 0) {
                setSliderIndex(0);
              }
            }}
            className={`${
              sliderIndex === 0
                ? "border-b border-blue-400"
                : ""
            } py-1 transition-transform`}
          >
            Eğitimlerimiz
          </button>
          <button
            onClick={() => {
              if (sliderIndex !== 1) {
                setSliderIndex(1);
              }
            }}
            className={`${
              sliderIndex === 1
                ? "border-b border-blue-400"
                : ""
            } py-1 transition-transform`}
          >
            Hizmetlerimiz
          </button>
        </div>
        {sliderIndex === 0 ? <TrainingSection /> : <ServicesSection />}
      </div>
    </div>
  );
};

export default Home;
